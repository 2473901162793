@import "../../../../../styles/variables";

.TableItemLock {
  &.secured {
    svg {
      color: @grey-color-base;
    }
  }
  &.unsecured {
    svg {
      color: @grey-shade-6;
    }
  }
}
