@import "../../../styles/variables";

.AtModal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: @pixels-sm;

  .AtModalContainerContent {
    flex: auto;
    overflow: auto;
    -ms-overflow-style: none; // Internet Explorer 10+
    scrollbar-width: none; // Firefox
    padding: @pixels-lg @pixels-xxl;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent; // Safari and Chrome
      display: none;
    }
  }

  .AtModalFooter {
    padding: @pixels-sm @pixels-md;
    flex: none;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    left: 0;
    .AtButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: @pixels-xs;
      .AtIcon {
        color: #fff;
        min-height: initial;
        min-width: initial;
      }
    }
  }
}
