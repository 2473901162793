@import "../../../styles/variables";

.AtTabs {
  .AtButtonTabs {
    margin-bottom: @margin-xxs;
    flex: 0 0;
  }

  .AtTable {
    height: 100%;
  }
}
