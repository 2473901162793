@import "../../../styles/variables";

.ReportLegendContainer {
  .ReportLegendItem {
    &.ReportRangeLegendItem {
      margin-right: @margin-xs;

      .range-icon {
        @bgColor: fade(@grey-shade-2, 30%);

        width: @pixels-lg;
        height: 20px;

        .border(@color: @bgColor);
        background: @bgColor;
        .borderRadius();

        &.team-concentration {
          background-color: @orange-shade-7;
          border-color: @atman-orange;
        }
      }
    }
  }
}
