@import "../../../styles/variables";

.AtActionContainer {
  .firstLine {
    .flexLayout();
    justify-content: space-around;

    .AtIcon {
      margin-right: @margin-sm;
    }

    .AtTitleSubtitle {
      flex: 2;
      text-align: left;
    }

    .secondaryContent {
      flex: 1;
    }

    .action {
      flex: 1;
      text-align: right;
    }
  }

  .secondLine {
    margin-top: @margin-xs;
  }
}
